export const homeContent = [
    {
        "id": "d6b9386d-1240-412c-b9d3-5402b0ef45f0",
        "title": "All New Tech. Still Amazing Hybrid. Adaptable.",
        "title_size": "xxl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7A4E681B244598BA0C70BC1132EBC98B_video_dashinit.mp4",
        "cta": "/#/feed",
        "icon": "01",
        "tag": ""
    },
    {
        "id": "7a177dad-eefd-4339-9752-eee9250e9ce0",
        "title": "This Should Be The 02. Worth The Wait.",
        "title_size": "xxl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-looks-094B7CFDEC4209479B52284500E120BE_video_dashinit.mp4",
        "cta": "https://www.lynkco.com",
        "icon": "01",
        "tag": ""
    },
    {
        "id": "b49a3114-1a53-46c6-af90-5ad15d91c6f9",
        "title": "Shareable. On Your Terms.",
        "title_size": "xxl",
        "verified": false,
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. Sed ut purus eget sapien.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-storageAA44E2530E508C510FB0EE43D75D3995_video_dashinit.mp4",
        "cta": "https://www.lynkco.com",
        "icon": "",
        "tag": ""
    },
    {
        "id": "bca3c74f-028b-4129-ade8-f530d23d5ed0",
        "title": "Always with you.",
        "title_size": "xxl",
        "verified": false,
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. Sed ut purus eget sapien.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-AQNUNv5KUh6pWatA80dg87qpHf1tLPji47nHfLFAZNTrMaamWBAmMDJah9z63F9TJraBo4ILVNc011xqTaX5SY8J.mp4",
        "cta": "https://www.lynkco.com",
        "icon": "",
        "tag": "Connected"
    },
    {
        "id": "bca3c74f-028b-4129-ade8-f530d23d5ed0",
        "title": "Service at Volvo dealerships. In good hands.",
        "title_size": "xxl",
        "verified": false,
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. Sed ut purus eget sapien.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-AQNUNv5KUh6pWatA80dg87qpHf1tLPji47nHfLFAZNTrMaamWBAmMDJah9z63F9TJraBo4ILVNc011xqTaX5SY8J.mp4",
        "cta": "https://www.lynkco.com",
        "icon": "",
        "tag": ""
    }
]

export const homeContentCarousel = [
    {
        "id": "d6b9386d-1240-412c-b9d3-5402b0ef45f0",
        "title": "The New 01",
        "title_size": "xxl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7A4E681B244598BA0C70BC1132EBC98B_video_dashinit.mp4",
        "cta": "/#/feed",
        "icon": "01",
        "tag": "Updated"
    },
    {
        "id": "7a177dad-eefd-4339-9752-eee9250e9ce0",
        "title": "The 02",
        "title_size": "xxl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-looks-094B7CFDEC4209479B52284500E120BE_video_dashinit.mp4",
        "cta": "https://www.lynkco.com",
        "icon": "01",
        "tag": "New"
    },
    {
        "id": "b49a3114-1a53-46c6-af90-5ad15d91c6f9",
        "title": "Car Sharing",
        "title_size": "xxl",
        "verified": false,
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. Sed ut purus eget sapien.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-storageAA44E2530E508C510FB0EE43D75D3995_video_dashinit.mp4",
        "cta": "https://www.lynkco.com",
        "icon": "car-sharing",
        "tag": ""
    },
    {
        "id": "bca3c74f-028b-4129-ade8-f530d23d5ed0",
        "title": "Connectivity",
        "title_size": "xxl",
        "verified": false,
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. Sed ut purus eget sapien.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-AQNUNv5KUh6pWatA80dg87qpHf1tLPji47nHfLFAZNTrMaamWBAmMDJah9z63F9TJraBo4ILVNc011xqTaX5SY8J.mp4",
        "cta": "https://www.lynkco.com",
        "icon": "connected",
        "tag": ""
    },
    {
        "id": "bca3c74f-028b-4129-ade8-f530d23d5ed0",
        "title": "Service",
        "title_size": "xxl",
        "verified": false,
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. Sed ut purus eget sapien.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-AQNUNv5KUh6pWatA80dg87qpHf1tLPji47nHfLFAZNTrMaamWBAmMDJah9z63F9TJraBo4ILVNc011xqTaX5SY8J.mp4",
        "cta": "https://www.lynkco.com",
        "icon": "workshop",
        "tag": ""
    },
    {
        "id": "bca3c74f-028b-4129-ade8-f530d23d5ed0",
        "title": "The 01 (pre-facelift)",
        "title_size": "xxl",
        "verified": false,
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. Sed ut purus eget sapien.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-AQNUNv5KUh6pWatA80dg87qpHf1tLPji47nHfLFAZNTrMaamWBAmMDJah9z63F9TJraBo4ILVNc011xqTaX5SY8J.mp4",
        "cta": "https://www.lynkco.com",
        "icon": "01",
        "tag": ""
    }
]
