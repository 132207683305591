const ButtonSmall = ({ text, url, wrap = false }) => { 
    if (!url) return null;

    const buttonClasses = `btn-secondary rounded-full border-0 border-white backdrop-blur-md bg-white/30 px-5 py-3 text-base text-lynk-body-medium text-white shadow-none border border-white/10 focus:outline-none focus:ring-none text-center inline-block no-underline ${wrap ? 'inline-block' : 'w-full'}`;

    return (
      <div className={wrap ? 'inline-block' : 'w-full'}>
        <a
          href={url}
          className={buttonClasses}
        >
          <p className={wrap ? 'whitespace-nowrap' : ''}>{text}</p>
        </a>
      </div>
    );
};
export default ButtonSmall;