import React, { useState } from 'react';
import Video from './video';
import ButtonSmall from '../buttons/btn-small';
import BottomSafearea from './bottomSafearea';
import VerifiedBadge from './verifiedBadge';
import Icon01 from '../icons/01-icon';

const VideoTitle = ({ title, size = 'sm', showVerifiedBadge = false }) => (
  <h2 className={`pb-2 text-white inline-flex items-center leading-none ${
    size === 'xl' 
      ? 'text-3xl text-lynk-heading-xlarge' 
      : 'text-lg text-lynk-body-medium'
  }`}>
    {title}
    {showVerifiedBadge && <VerifiedBadge />}
  </h2>
);

const VideoDescription = ({ description }) => {
  if (!description) return null;
  return (
    <p className="pt-1 text-white text-lynk-body-regular text-sm">{description}</p>
  );
};

const VideoControls = ({ isMuted, onMuteToggle }) => {
    return (
      <div className="absolute bottom-20 right-2 flex flex-col items-center space-y-4">
        <button
          onClick={onMuteToggle}
          className="bg-gray-800 bg-opacity-50 rounded-full p-2 text-white hover:bg-opacity-75 transition-all duration-200"
        >
          {isMuted ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
              <path d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 001.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06zM17.78 9.22a.75.75 0 10-1.06 1.06L18.44 12l-1.72 1.72a.75.75 0 001.06 1.06l1.72-1.72 1.72 1.72a.75.75 0 101.06-1.06L20.56 12l1.72-1.72a.75.75 0 00-1.06-1.06l-1.72 1.72-1.72-1.72z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
              <path d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 001.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06zM18.584 5.106a.75.75 0 011.06 0c3.808 3.807 3.808 9.98 0 13.788a.75.75 0 11-1.06-1.06 8.25 8.25 0 000-11.668.75.75 0 010-1.06z" />
              <path d="M15.932 7.757a.75.75 0 011.061 0 6 6 0 010 8.486.75.75 0 01-1.06-1.061 4.5 4.5 0 000-6.364.75.75 0 010-1.06z" />
            </svg>
          )}
        </button>
      </div>
    );
};

const VideoWithText = ({ id, src, title, title_size, verified, description, cta, preload, onVisible }) => { 
    const [isMuted, setIsMuted] = useState(true);

    const handleMuteToggle = () => {
        setIsMuted(!isMuted);
    };

    return (
        <div className="relative">
            <Video 
                id={id} 
                src={src} 
                preload={preload} 
                onVisible={onVisible} 
            />
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparen">
                <div className="flex flex-col h-full px-4 pb-3.5">
                    <div className="flex-grow">
                        <Icon01 size="xxl" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <VideoTitle title={title} size={title_size} showVerifiedBadge={verified} />
                        {description && <div className="pb-3"><VideoDescription description={description} /></div>}
                        <ButtonSmall text={"Buy"} url={cta} />
                    </div>
                </div>
                {/* <VideoControls isMuted={isMuted} onMuteToggle={handleMuteToggle} /> */}
                <BottomSafearea/>
            </div>
        </div>
    );
};

export default VideoWithText;
