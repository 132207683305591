import React, { forwardRef, useRef, useEffect, useState, useCallback } from 'react';
import { useMute } from './muteContext'; 
import { useInView } from 'react-intersection-observer';
import MuteIndicator from './muteIndicator';
import SwipeUpIndicator from './swipeUpIndicator';

const Video = forwardRef(({ id, src, preload = "none", onVisible = () => {}, ...props }, ref) => {
    //const [isMuted, setIsMuted] = useState(true); // State to track if the video is muted
    // const [isBuffering, setIsBuffering] = useState(false); // State to track buffering
    const { isMuted, toggleMute } = useMute();
    const [isLoading, setIsLoading] = useState(true); // State to track skeleton loading
    const [showBufferingIndicator, setShowBufferingIndicator] = useState(false); // State to control the visibility of the buffering indicator
    const [progress, setProgress] = useState(0); // For tracking playback progress
    const [showIndicator, setShowIndicator] = useState(false); // State to control the visibility of the mute/unmute indicator
    const [hasLooped, setHasLooped] = useState(false);

    const videoRef = useRef();
    const { ref: inViewRef, inView } = useInView({
        // Triggers as soon as 50% of view
        threshold: 0.5,
        // Trigger the inView callback every time the visibility changes, not just once
        triggerOnce: false,
    });

    // Listen for 'loadedmetadata' to hide skeleton sooner
    const handleLoadedMetadata = useCallback(() => {
        setIsLoading(false); // Hide skeleton as soon as metadata is loaded
        setProgress(0); // Reset progress when the video metadata loads
    }, []);

    // Progress bar updates
    const handleTimeUpdate = useCallback(() => {
        if (videoRef.current) {
            const currentProgress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
            setProgress(currentProgress);
        }
    }, []);

    // Handle user clicking on video
    const handleVideoClick = () => {
        toggleMute();
        setShowIndicator(true);
        setTimeout(() => setShowIndicator(false), 1000);
    };

    // Toggle mute state
    // const toggleMute = useCallback(() => {
    //     if (videoRef.current) {
    //         const currentlyMuted = videoRef.current.muted;
    //         videoRef.current.muted = !currentlyMuted;
    //         setIsMuted(!currentlyMuted);
    //         console.log("Muted: " + currentlyMuted);
    //         setShowIndicator(true); // Show the indicator
    //         setTimeout(() => setShowIndicator(false), 1000); // Hide the indicator after 1.4 seconds
    //     }
    // }, []);

    // Restart the video when it ends
    const handleEnded = useCallback(() => {
        console.log("Video has looped");
        if (videoRef.current) {
            videoRef.current.play().catch(error => console.error("Error replaying video:", error));
        }
        setHasLooped(true);
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        if (inView) {
            video.play().catch((error) => console.error("Error playing video:", error));
            onVisible();
        } else {
            video.pause(); // Pause video
            video.currentTime = 0; // Reset video to start
            setHasLooped(false); // Remove loop state
        }

        const handleWaiting = () => {
            console.log('Video is buffering');
            if (!isLoading) {   
                setShowBufferingIndicator(true);
            }
          };
      
          const handlePlaying = () => {
            console.log('Video resumed playing');
            setShowBufferingIndicator(false);
          };

        // Listening for 'loadedmetadata' event to hide the skeleton sooner
        video.addEventListener('loadedmetadata', handleLoadedMetadata);
        video.addEventListener('timeupdate', handleTimeUpdate);
        video.addEventListener('ended', handleEnded);
        // Listen to buffering events
        video.addEventListener('waiting', handleWaiting);
        video.addEventListener('playing', handlePlaying);

        return () => {
            video.removeEventListener('loadedmetadata', handleLoadedMetadata);
            video.removeEventListener('timeupdate', handleTimeUpdate);
            video.removeEventListener('ended', handleEnded);
            video.removeEventListener('waiting', handleWaiting);
            video.removeEventListener('playing', handlePlaying);
            
        };
    }, [inView, onVisible, handleLoadedMetadata, handleTimeUpdate, handleEnded, isMuted, isLoading, showBufferingIndicator, hasLooped]);

    return (
        <>
            {/* {isLoading && <Skeleton />} */}
            <div style={{ position: 'relative', width: '100%', height: '100%' }} onClick={handleVideoClick}>
                {showIndicator && <MuteIndicator isMuted={isMuted} />}
                {/* {showBufferingIndicator && <BufferIndicator />} */}
                <video
                    ref={(node) => { inViewRef(node); videoRef.current = node; }}
                    src={src}
                    preload={preload}
                    {...props}
                    muted={isMuted} // Controlled by global context
                    playsInline
                    className="h-screen w-screen object-cover"
                />
                {!isLoading && (
                    <>
                    <div className="absolute top-0 left-0 right-0 fill-safearea-top flex-col justify-center">
                        <div className="flex justify-center">
                            <div className="mx-3 mt-2.5 h-1 w-28 backdrop-blur-sm bg-white/30 rounded-full relative">
                                {showBufferingIndicator && <div className="bg-white/80 h-full transition-all duration-700 ease-out rounded-full animate-ping-loading absolute top-0 left-0 right-0"></div>}
                                {!showBufferingIndicator && <div className="bg-white/50 h-full transition-all duration-700 ease-out rounded-full" style={{ width: `${progress}%` }}></div>}
                            </div>
                        </div>
                        <SwipeUpIndicator hasLooped={hasLooped} />
                    </div>
                    </>
                )}
            </div>
        </>
    );
});

export default Video;
