import React, { useState, useEffect } from 'react';
import VideoWithText from './videoWithText';
import Skeleton from './skeleton';
import { testContent } from './contentData';
  
  export default function Feed() {

    const content = testContent; // Select the content to use manually for now
    console.log(content);
    
    // Skeleton loading
    const [contentLoaded, setContentLoaded] = useState(false);
    async function skeletonLoading() {
        await new Promise(resolve => setTimeout(resolve, 500));
        setContentLoaded(true);
    }
    skeletonLoading();

    const [activeIndex, setActiveIndex] = useState(0); // Initialize to -1 indicating no active video initially
    const [itemsToShow, setItemsToShow] = useState(content.slice(0)); // Initially show first 3 items

    useEffect(() => {
        const startIndex = Math.max(0, activeIndex - 1);
        const endIndex = Math.min(content.length, activeIndex + 3);
        
        setItemsToShow(prevItems => {
            const newItems = content.slice(startIndex, endIndex);
            const combinedItems = [...prevItems, ...newItems];
            
            // Remove duplicates
            const uniqueItems = Array.from(new Set(combinedItems.map(item => item.id)))
                .map(id => combinedItems.find(item => item.id === id));
            
            return uniqueItems;
        });
    }, [activeIndex, content]);

    const handleVideoVisible = (index) => {
        setActiveIndex(index);
    }; 

    const handlePreload = (index, indexActive) => {    
        if (index < indexActive - 2) {
            return "none";
        } else if (index === indexActive - 2) {
            return "none";
        } else if (index === indexActive - 1) {
            return "metadata";
        } else if (index === indexActive) {
            return "auto";
        } else if (index === indexActive + 1) {
            return "metadata";
        } else if (index === indexActive + 2) {
            return "none";
        } else if (index > indexActive + 2) {
            return "none";
        }
    };

    const handleSource = (index, indexActive, source) => {    
        if (index < indexActive - 2) {
            return "";
        } else if (index > indexActive - 2) {
            return source;
        }
    };
    
  
      return (
            <>
            <div style={contentLoaded ? { display: "none" } : {}}>
              <Skeleton></Skeleton>
            </div>
            <div 
            style={contentLoaded ? {} : { display: 'none' } }
            className="overflow-y-scroll snap-mandatory snap-y h-screen w-screen"
            >
                {itemsToShow.map((el, index) => {
                    // Determine if this video should auto-play based on activeIndex
                    const autoPlay = index === activeIndex;
                    // Preload the next video in the sequence, but dont preload all videos
                    // const preload = index === activeIndex + 1 ? "auto" : (index > activeIndex + 1 ? "none" : "metadata");
                    const preload = handlePreload(index, activeIndex);
                    // Handle video source to help browser caching policies not cache too much and free up memory
                    const source = handleSource(index, activeIndex, el.video);

                    return (
                        <div key={el.id} className="h-screen w-screen snap-start snap-always fill-safearea">
                            <VideoWithText
                                id={el.id}
                                src={source}
                                title={el.title}
                                title_size={el.title_size}
                                verified={el.verified}
                                description={el.description}
                                cta={el.cta}
                                autoPlay={autoPlay}
                                preload={preload}
                                onVisible={() => handleVideoVisible(index)} />
                        </div>
                    );
                })}
            </div>
              </>
      );
  }
