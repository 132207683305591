import React from 'react';

const VerifiedBadge = () => (
  <span className="ml-1.5 inline-flex items-center justify-center bg-cyan-400 rounded-full h-3.5 w-3.5">
    <svg className="text-white h-3.5 w-3.5" viewBox="4 6 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 12l2 2 4-4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </span>
);

export default VerifiedBadge;