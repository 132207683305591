const MuteIndicator = ({ isMuted }) => {
    return (
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 backdrop-blur-sm bg-black/50 rounded-full p-4 animate-fadeInOutScale">
        {isMuted ? (
          // Muted Icon
          <svg className="h-10 w-10" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.875 8.62486H3.5C2.948 8.62486 2.5 9.07286 2.5 9.62486V14.3749C2.5 14.9269 2.948 15.3749 3.5 15.3749H5.875L9.854 18.7459C10.504 19.2969 11.5 18.8349 11.5 17.9829V6.01686C11.5 5.16486 10.503 4.70286 9.854 5.25386L5.875 8.62486Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.5371 15.978C16.6991 15.256 17.5001 13.754 17.5001 12.005C17.5001 10.256 16.6991 8.75297 15.5371 8.02197" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.505 19.065C20.313 17.51 21.5 14.93 21.5 12.003C21.5 9.07204 20.311 6.49004 18.5 4.93604" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3.21777 1.81885L19.5466 20.537" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
        ) : (
          // Unmuted Icon
          <svg className="h-10 w-10" stroke="currentColor" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5415 8.62486H4.1665C3.6145 8.62486 3.1665 9.07286 3.1665 9.62486V14.3749C3.1665 14.9269 3.6145 15.3749 4.1665 15.3749H6.5415L10.5205 18.7459C11.1705 19.2969 12.1665 18.8349 12.1665 17.9829V6.01686C12.1665 5.16486 11.1695 4.70286 10.5205 5.25386L6.5415 8.62486Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.2036 15.978C17.3656 15.256 18.1666 13.754 18.1666 12.005C18.1666 10.256 17.3656 8.75297 16.2036 8.02197" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.1715 19.065C20.9795 17.51 22.1665 14.93 22.1665 12.003C22.1665 9.07204 20.9775 6.49004 19.1665 4.93604" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        )}
      </div>
    );
  };

  export default MuteIndicator;