import React from 'react';

const Icon01 = ({ size = 'md', fill = 'white' }) => {
  const sizes = {
    sm: { width: 16, height: 16 },
    md: { width: 24, height: 24 },
    lg: { width: 32, height: 32 },
    xl: { width: 48, height: 48 },
    xxl: { width: 64, height: 64 },
  };

  const { width, height } = sizes[size] || sizes.md;

  return (
    <svg width={width} height={height} viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1738 5.0869C10.1738 7.8963 7.8963 10.1738 5.08691 10.1738C2.27749 10.1738 0 7.8963 0 5.0869C0 2.27749 2.27749 0 5.08691 0C7.8963 0 10.1738 2.27749 10.1738 5.0869ZM7.6304 5.086C7.6304 6.4907 6.49168 7.6295 5.08697 7.6295C3.68226 7.6295 2.54351 6.4907 2.54351 5.086C2.54351 3.6813 3.68226 2.54255 5.08697 2.54255C6.49168 2.54255 7.6304 3.6813 7.6304 5.086ZM11.8953 3.14182L15.4562 0.08967H17.9996V12.2983H15.4562V3.44703L13.4214 5.1766L11.8953 3.14182Z"
        fill={fill}
      />
    </svg>
  );
};

export default Icon01;
