import BottomSafearea from "./bottomSafearea";

export default function Skeleton() {

    return (
        <>
            <div>
                <div className="flex flex-col h-screen w-screen container mx-auto bg-lynk-black relative antialiased">
                    <div className="flex flex-col-reverse h-screen w-screen bg-lynk-black animate-pulse">
                    
                            <div className="flex flex-col">
                                <div className="p-4 pb-2 animate-pulse">
                                    <div class="h-4 mb-3 rounded-full bg-neutral-600 w-60"></div>
                                    <div class="h-2 mb-2 rounded-full bg-neutral-600"></div>
                                    <div class="h-2 mb-2 rounded-full bg-neutral-600"></div>
                                    <div class="h-2 rounded-full bg-neutral-600"></div>
                                </div>
                                <BottomSafearea />
                            </div>
                  
                    </div>
                </div>
            </div> 
        </>
    )
  };