import React from 'react';
import ReactDOM from 'react-dom/client';
import { createHashRouter, RouterProvider } from "react-router-dom";
import { MuteProvider } from './feed/muteContext';
import "./index.css";
import Root from './root';
import ErrorPage from "./error-page";
import Feed from './feed/feed';
import Home from './home/home'; // Add this import
import reportWebVitals from './reportWebVitals';

const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true, // Add this line
        element: <Home />, // Add this line
      },
      {
        path: "feed/",
        element: <Feed />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MuteProvider>
      <RouterProvider router={router} />
    </MuteProvider>
  </React.StrictMode>
);

reportWebVitals();
