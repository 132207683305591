import React, { createContext, useContext, useState } from 'react';

// Create a context with default values
const MuteContext = createContext({
  isMuted: true,
  toggleMute: () => {
    console.log("CONTEXT: Mute toggle");
  },
});

// Create a provider component
export const MuteProvider = ({ children }) => {
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    console.log("PROVIDER: Mute toggle to: " + isMuted);
  };

  return (
    <MuteContext.Provider value={{ isMuted, toggleMute }}>
      {children}
    </MuteContext.Provider>
  );
};

// Hook to use the mute context
export const useMute = () => useContext(MuteContext);
