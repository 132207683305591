export const testContent = [
    {
        "id": "d6b9386d-1240-412c-b9d3-5402b0ef45f0",
        "title": "Meet the New 01. Engineered with a Long Electric Range, for the Life You Live.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7A4E681B244598BA0C70BC1132EBC98B_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "7a177dad-eefd-4339-9752-eee9250e9ce0",
        "title": "Swedish Design. Global Appeal.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-looks-094B7CFDEC4209479B52284500E120BE_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "bca3c74f-028b-4129-ade8-f530d23d5ed0",
        "title": "Family-Friendly, Big or Small: Space for Everyone and Everything You Need.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-AQNUNv5KUh6pWatA80dg87qpHf1tLPji47nHfLFAZNTrMaamWBAmMDJah9z63F9TJraBo4ILVNc011xqTaX5SY8J.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "b49a3114-1a53-46c6-af90-5ad15d91c6f9",
        "title": "Room for More: Space that Fits Your Life",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-storageAA44E2530E508C510FB0EE43D75D3995_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "8ac5d7c0-1f47-420d-bcf2-c20b1b3e0bd1",
        "title": "LED Headlights & Taillights: Guiding You Around Every Bend.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-lights-3443741C4C876EAC30CC27E1F0D31CA7_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "04287acc-05d3-45d5-b8ec-c0a11cab98f4",
        "title": "Effortless Charging. Ready for Everyday.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-charge-294F697B664E1E31CDFB20D9E2ECBFB7_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "3da1df07-da5a-43c2-9567-f4a0a20ba12b",
        "title": "Automatic Gearbox: Smooth Shifts for Effortless Driving.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-gearbox-AQPAJIK51duq9NTvlrEyC6QEMecbcsCqgh9TBQLiXhO5aKHLtao55WWU_UIsELwLC1Et0OrfMjoN8ZPrS5gKkT8i.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "ad9f205e-970a-429c-9a1f-0ff74e757f26",
        "title": "Tow Hook Capability: Ready for Everything, Even a Food Truck.*",
        "title_size": "xl",
        "verified": false,
        "description": "*Optional extra.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-trailer-AQM5FG1Pek309vH7ouOx5AHwtN4jYo2VIK7HxmCMXe1fqDte3yCV2iWSprDZK-gMV6MZ8QYi6LWIJX4YZzfFeS7A.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "6d215620-25c9-4dc3-b5c3-a1a34c60a2af",
        "title": "75 km pure electric range in a plug-in hybrid motor*",
        "title_size": "xl",
        "verified": false,
        "description": "*WLTP-certified. Your mileage may vary.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7E4B692FF59ADE1C7F28F647B9F42E83_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "70e1a7ca-08fb-48ac-b3c9-1032f008e400",
        "title": "Automatic Parking: Your Perfect Partner in Tight Spaces.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-parking-0A4D6453F3C8AAE27FEFF9A7C3108C98_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "3c9fbc29-7456-40a3-a770-c607e0c6585f",
        "title": "360 Camera with 3D View: For Those Who Like to Take Control.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-564B03A11AFC82F6F82FCB5EC28403B7_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "5393497b-7ecb-4db2-ae44-c09af864d545",
        "title": "Panoramic Sunroof: Open Up to Endless Views.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-sunroof-AE46A2263BD4AEA7FEC696AC5CD170AC_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "60cae827-4bbd-4a52-b24b-ac93374a606c",
        "title": "Electric Tailgate: Convenience at the Touch of a Button.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-8446CE9F760D9EE85153EAA33F04DD96_video_dashinit.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "4c8f286e-c1ca-4b53-9b36-766eef7682af",
        "title": "Take your meetings anywhere with Microsoft Teams™.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-GA0hmhG_xC4AJ34AAEbMMZ_0NZ5zbq_EAAAF.mp4",
        "cta": "https://www.lynkco.com"
    },
    {
        "id": "6d279fcc-d77c-43e6-8469-a075c98e787c",
        "title": "Surf the web with Vivaldi Browser™.",
        "title_size": "xl",
        "verified": false,
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-421924788_745573314299133_1791060456243443201_n.mp4",
        "cta": "https://www.lynkco.com"
    }
  ]



  export const cx11 = [
    {
        "id": 1,
        "title": "Meet the 01. Everything awesome comes standard.",
        "title_size": "xl",
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7A4E681B244598BA0C70BC1132EBC98B_video_dashinit.mp4"
    },
    {
        "id": 2,
        "title": "75 km pure electric range in a plug-in hybrid motor.",
        "title_size": "xl",
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7E4B692FF59ADE1C7F28F647B9F42E83_video_dashinit.mp4"
    },
    {
        "id": 3,
        "title": "The 12,7 inch center display holds everything you can think of. ",
        "title_size": "xl",
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-1D43BA25832DACF8BC2F91D2D6F777A2_video_dashinit.mp4"
    },
    {
        "id": 4,
        "title": "Get comfy in the world's first car with seats made of ECONYL®, a regenerated nylon material made from recycled fishing lines and other plastic waste.",
        "title_size": "xl",
        "description": "",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-8446CE9F760D9EE85153EAA33F04DD96_video_dashinit.mp4"
    },
    {
        "id": 5,
        "title": "Lynk & Co",
        "description": "A very important announcement: open sunroof season is here! ☀️",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-C9408D1393C95E1E5A6A0A5E7C48498A_video_dashinit.mp4"
    },
    {
        "id": 6,
        "title": "Lynk & Co",
        "description": "@suzanneschulting makes the fall look like a breeze.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-GICWmADVr1h0S3QFABSeOm2uQUFSbq_EAAAF.mp4"
    },
    {
        "id": 7,
        "title": "Lynk & Co",
        "description": "Thanks to @sanderbeijer for sharing this flex! Got a shiny new 01 of your own? Tag us or use #MyLynkCoJourney.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-GMLoaxGwmqWDepwAABOiVsK22g93bq_EAAAF.mp4"
    },
    {
        "id": 8,
        "title": "Lynk & Co",
        "description": "What’s the best background for the 01? Help us decide!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-421924788_745573314299133_1791060456243443201_n.mp4"
    },
    {
        "id": 9,
        "title": "Lynk & Co",
        "description": "Long story short, we had a great time at @welovegreenfestival. #Paris #Festivalseason",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-564B03A11AFC82F6F82FCB5EC28403B7_video_dashinit.mp4"
    },
    {
        "id": 10,
        "title": "Lynk & Co",
        "description": "Traveling this summer? Take a minute to prepare your 01 and avoid last-minute vacation stress. A few pro tips: if you’re taking the boat or the train, adjust your car alarm settings – the movement could trigger the alarm and empty the battery. And if you’re carrying some extra luggage, get a roof rack at your nearest Club!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-GA0hmhG_xC4AJ34AAEbMMZ_0NZ5zbq_EAAAF.mp4"
    },
    {
        "id": 11,
        "title": "Lynk & Co",
        "description": "Hot summer, meet cool ride.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-824995764A4E3C7853894C0B5050BB88_video_dashinit.mp4"
    },
    {
        "id": 12,
        "title": "Lynk & Co Club",
        "description": "Exploring the beautiful streets of Hamburg in the 01. Do you have a favorite city to drive around in?",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/club-5B4C192DEC9CE799D18B58ACC4DF43AB_video_dashinit.mp4"
    },
    {
        "id": 13,
        "title": "Lynk & Co Club",
        "description": "Not a car expert? No problem! Our non-experts Freddie, Pamela and Saibou are here to explain Lynk & Co and the 01 to you. Get ready.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/clubs-05431F1BA7C908BF272416F0D1DFD98A_video_dashinit.mp4"
    },
    {
        "id": 14,
        "title": "Lynk & Co Club",
        "description": "Events, workshops, test drives, concerts, cool (but not too cool!) people… 01 driver and non-car-expert Saibou stopped by our Berlin Club for a chat about what goes on in the world of Lynk & Co.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/clubs-0E42731790FEEB16B8A7757E2DE3988E_video_dashinit.mp4"
    }
  ]

  export const e335 = [
    {
        "id": 1,
        "title": "Lynk & Co",
        "description": "Want to take a test drive on your terms? Depending on where you are, we'll bring a Lynk & Co 01 to your house, work, or wherever you want so you can take it for a spin. Visit our website to book your test drive now!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7A4E681B244598BA0C70BC1132EBC98B_video_dashinit.mp4"
    },
    {
        "id": 2,
        "title": "Lynk & Co",
        "description": "Great times, gorgeous city, damn good car. Want to try it out for yourself? Choose your location in one of our seven countries and book your own free test drive on our website",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7E4B692FF59ADE1C7F28F647B9F42E83_video_dashinit.mp4"
    },
    {
        "id": 3,
        "title": "Lynk & Co",
        "description": "Capture every angle of the action!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-1D43BA25832DACF8BC2F91D2D6F777A2_video_dashinit.mp4"
    },
    {
        "id": 4,
        "title": "Lynk & Co",
        "description": "Work from wherever with our new in-car Meetings app. Created in collaboration with Microsoft, the Meetings app lets you join in Microsoft Teams calls from your car. No fussing with your phone required.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-8446CE9F760D9EE85153EAA33F04DD96_video_dashinit.mp4"
    },
    {
        "id": 5,
        "title": "Lynk & Co",
        "description": "A very important announcement: open sunroof season is here! ☀️",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-C9408D1393C95E1E5A6A0A5E7C48498A_video_dashinit.mp4"
    },
    {
        "id": 6,
        "title": "Lynk & Co",
        "description": "@suzanneschulting makes the fall look like a breeze.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-GICWmADVr1h0S3QFABSeOm2uQUFSbq_EAAAF.mp4"
    },
    {
        "id": 7,
        "title": "Lynk & Co",
        "description": "Thanks to @sanderbeijer for sharing this flex! Got a shiny new 01 of your own? Tag us or use #MyLynkCoJourney.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-GMLoaxGwmqWDepwAABOiVsK22g93bq_EAAAF.mp4"
    },
    {
        "id": 8,
        "title": "Lynk & Co",
        "description": "What’s the best background for the 01? Help us decide!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-421924788_745573314299133_1791060456243443201_n.mp4"
    },
    {
        "id": 9,
        "title": "Lynk & Co",
        "description": "Long story short, we had a great time at @welovegreenfestival. #Paris #Festivalseason",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-564B03A11AFC82F6F82FCB5EC28403B7_video_dashinit.mp4"
    },
    {
        "id": 10,
        "title": "Lynk & Co",
        "description": "Traveling this summer? Take a minute to prepare your 01 and avoid last-minute vacation stress. A few pro tips: if you’re taking the boat or the train, adjust your car alarm settings – the movement could trigger the alarm and empty the battery. And if you’re carrying some extra luggage, get a roof rack at your nearest Club!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-GA0hmhG_xC4AJ34AAEbMMZ_0NZ5zbq_EAAAF.mp4"
    },
    {
        "id": 11,
        "title": "Lynk & Co",
        "description": "Hot summer, meet cool ride.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-824995764A4E3C7853894C0B5050BB88_video_dashinit.mp4"
    },
    {
        "id": 12,
        "title": "Lynk & Co Club",
        "description": "Exploring the beautiful streets of Hamburg in the 01. Do you have a favorite city to drive around in?",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/club-5B4C192DEC9CE799D18B58ACC4DF43AB_video_dashinit.mp4"
    },
    {
        "id": 13,
        "title": "Lynk & Co Club",
        "description": "Not a car expert? No problem! Our non-experts Freddie, Pamela and Saibou are here to explain Lynk & Co and the 01 to you. Get ready.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/clubs-05431F1BA7C908BF272416F0D1DFD98A_video_dashinit.mp4"
    },
    {
        "id": 14,
        "title": "Lynk & Co Club",
        "description": "Events, workshops, test drives, concerts, cool (but not too cool!) people… 01 driver and non-car-expert Saibou stopped by our Berlin Club for a chat about what goes on in the world of Lynk & Co.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/clubs-0E42731790FEEB16B8A7757E2DE3988E_video_dashinit.mp4"
    }
  ]

  export const sharing = [
    {
        "id": 1,
        "title": "Lynk & Co",
        "description": "Want to take a test drive on your terms? Depending on where you are, we'll bring a Lynk & Co 01 to your house, work, or wherever you want so you can take it for a spin. Visit our website to book your test drive now!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7A4E681B244598BA0C70BC1132EBC98B_video_dashinit.mp4"
    },
    {
        "id": 2,
        "title": "Lynk & Co",
        "description": "Great times, gorgeous city, damn good car. Want to try it out for yourself? Choose your location in one of our seven countries and book your own free test drive on our website",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-7E4B692FF59ADE1C7F28F647B9F42E83_video_dashinit.mp4"
    },
    {
        "id": 3,
        "title": "Lynk & Co",
        "description": "Capture every angle of the action!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-1D43BA25832DACF8BC2F91D2D6F777A2_video_dashinit.mp4"
    },
    {
        "id": 4,
        "title": "Lynk & Co",
        "description": "Work from wherever with our new in-car Meetings app. Created in collaboration with Microsoft, the Meetings app lets you join in Microsoft Teams calls from your car. No fussing with your phone required.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-8446CE9F760D9EE85153EAA33F04DD96_video_dashinit.mp4"
    },
    {
        "id": 5,
        "title": "Lynk & Co",
        "description": "A very important announcement: open sunroof season is here! ☀️",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-C9408D1393C95E1E5A6A0A5E7C48498A_video_dashinit.mp4"
    },
    {
        "id": 6,
        "title": "Lynk & Co",
        "description": "@suzanneschulting makes the fall look like a breeze.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-GICWmADVr1h0S3QFABSeOm2uQUFSbq_EAAAF.mp4"
    },
    {
        "id": 7,
        "title": "Lynk & Co",
        "description": "Thanks to @sanderbeijer for sharing this flex! Got a shiny new 01 of your own? Tag us or use #MyLynkCoJourney.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/01-GMLoaxGwmqWDepwAABOiVsK22g93bq_EAAAF.mp4"
    },
    {
        "id": 8,
        "title": "Lynk & Co",
        "description": "What’s the best background for the 01? Help us decide!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-421924788_745573314299133_1791060456243443201_n.mp4"
    },
    {
        "id": 9,
        "title": "Lynk & Co",
        "description": "Long story short, we had a great time at @welovegreenfestival. #Paris #Festivalseason",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-564B03A11AFC82F6F82FCB5EC28403B7_video_dashinit.mp4"
    },
    {
        "id": 10,
        "title": "Lynk & Co",
        "description": "Traveling this summer? Take a minute to prepare your 01 and avoid last-minute vacation stress. A few pro tips: if you’re taking the boat or the train, adjust your car alarm settings – the movement could trigger the alarm and empty the battery. And if you’re carrying some extra luggage, get a roof rack at your nearest Club!",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-GA0hmhG_xC4AJ34AAEbMMZ_0NZ5zbq_EAAAF.mp4"
    },
    {
        "id": 11,
        "title": "Lynk & Co",
        "description": "Hot summer, meet cool ride.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/apps-824995764A4E3C7853894C0B5050BB88_video_dashinit.mp4"
    },
    {
        "id": 12,
        "title": "Lynk & Co Club",
        "description": "Exploring the beautiful streets of Hamburg in the 01. Do you have a favorite city to drive around in?",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/club-5B4C192DEC9CE799D18B58ACC4DF43AB_video_dashinit.mp4"
    },
    {
        "id": 13,
        "title": "Lynk & Co Club",
        "description": "Not a car expert? No problem! Our non-experts Freddie, Pamela and Saibou are here to explain Lynk & Co and the 01 to you. Get ready.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/clubs-05431F1BA7C908BF272416F0D1DFD98A_video_dashinit.mp4"
    },
    {
        "id": 14,
        "title": "Lynk & Co Club",
        "description": "Events, workshops, test drives, concerts, cool (but not too cool!) people… 01 driver and non-car-expert Saibou stopped by our Berlin Club for a chat about what goes on in the world of Lynk & Co.",
        "video": "https://stvideofeeddev001.blob.core.windows.net/videos/clubs-0E42731790FEEB16B8A7757E2DE3988E_video_dashinit.mp4"
    }
  ]