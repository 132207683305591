import React, { useState } from 'react';
import { homeContent, homeContentCarousel } from './homeData';
import ButtonSmall from '../buttons/btn-small';

import Icon01 from '../icons/01-icon';
import IconLogo from '../icons/logo-icon';
import IconSharing from '../icons/sharing-icon';
import IconWorkshop from '../icons/workshop-icon';
import IconConnected from '../icons/connected-icon';

const CardTitle = ({ title, size = 'sm', showVerifiedBadge = false, textAlign = 'left', color = 'white' }) => (
    <h2 className={`inline-flex items-center leading-none ${
      size === 'xxxl'
        ? 'text-5xl text-lynk-heading-xlarge'
        : size === 'xxl'
          ? 'text-4xl text-lynk-heading-xlarge'
          : size === 'xl' 
            ? 'text-3xl text-lynk-heading-xlarge' 
            : size === 'sm'
              ? 'text-base leading-snug'
              : 'text-lg text-lynk-heading-xlarge'
    } ${
      textAlign === 'center' ? 'text-center' :
      textAlign === 'right' ? 'text-right' : 'text-left'
    } ${
      color === 'white' ? 'text-white' :
      color === 'black' ? 'text-black' :
      `${color}`
    }`}>
      {title}
    </h2>
  );

  const CardDescription = ({ description, textAlign = 'left' }) => {
    if (!description) return null;
    return (
      <p className={`text-white text-lynk-body-regular text-base ${
        textAlign === 'center' ? 'text-center' :
        textAlign === 'right' ? 'text-right' : 'text-left'
      }`}>
        {description}
      </p>
    );
  };

  const LynkPill = ({ text }) => (
    <div className="inline-flex items-center px-2 py-0.5 rounded-full backdrop-blur-md bg-indigo-500/50 text-lynk-neutral-700 w-fit border border-indigo-500/30">
      <span className="text-[0.65rem] text-lynk-body-medium whitespace-nowrap text-white text-opacity-90 uppercase tracking-wide leading-none">{text}</span>
    </div>
  );

const CarouselItem = ({ item }) => {
  const getIcon = (iconName) => {
    switch (iconName) {
      case '01':
        return <Icon01 size="lg" />;
      case 'car-sharing':
        return <IconSharing size="lg" />;
      case 'workshop':
        return <IconWorkshop size="lg" />;
      case 'connected':
        return <IconConnected size="lg" />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-shrink-0 py-6 ml-9 rounded-lg">
      <div className="flex flex-col items-center gap-4 w-full h-full">
        {item.icon && getIcon(item.icon)}
        {/* <div className="h-6 pt-0">
          {item.tag && <LynkPill text={item.tag} />}
        </div> */}
        {/* <CardTitle title={item.title} size="sm" textAlign="center" color="text-zinc-400" /> */}
        <span className="text-lynk-body-regular text-lynk-neutral-200 text-xs">{item.title}</span>
      </div>
    </div>
  );
};

const HorizontalCarousel = ({ items }) => {
  return (
    <div className="overflow-x-auto snap-x snap-mandatory scrollbar-visible border-y border-white/10">
      <div className="flex h-full">
        {items.map((item, index) => (
          <CarouselItem key={item.id || index} item={item} />
        ))}
      </div>
    </div>
  );
};

const CardWithVideo = ({ video }) => {

  return (
    <div className="w-full rounded-2xl overflow-hidden border border-white/10 shadow-lg">
      <div className="relative min-h-80 h-[calc(100vh-12rem)]">
        <video
          src={video.video}
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted={true}
          playsInline
        />
        <div className="absolute inset-0 flex flex-col justify-between bg-gradient-to-b from-black/60 to-transparent">
          <div className="flex flex-col items-center gap-5 p-8 py-16">
              {video.icon && <Icon01 size="xxl"/>}
              {video.tag && <LynkPill text={video.tag} />}
              <CardTitle title={video.title} size={video.title_size} showVerifiedBadge={video.verified} textAlign="center" />
              <CardDescription description={video.description} textAlign='center' />
              <ButtonSmall text="Explore" url={video.cta} wrap={true} />
          </div>
          <div className="px-5 pb-5">
            <div className="flex flex-col gap-3.5 pb-5 items-center">
              
            </div>
            <div className="flex flex-col gap-2.5">
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  // Add a check to ensure homeContent exists and is an array
  const videos = Array.isArray(homeContent) ? homeContent : [];

  return (
    <div className="bg-lynk-neutral-900 min-h-screen w-full max-w-screen-sm mx-auto">
      <div className="flex flex-col">
        <div className="flex flex-col items-center p-2.5 pt-8 gap-5 bg-lynk-gradient overflow-hidden">
          <IconLogo width={135} height={20} />
          <div className="flex flex-col gap-2 items-center">
            {/* <LynkPill text="New cars" /> */}
            {/* Add the HorizontalCarousel component here */}
        
            {/* <div className="py-16">
              <CardTitle title="Cars for people. For progress." size="xxl" textAlign="center" />
            </div> */}
            {/* <CardDescription description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. Sed ut purus eget sapien." textAlign='center' /> */}
          </div>
        
        {/* <HorizontalCarousel items={homeContentCarousel} /> */}
        </div>
        
        <div className="flex flex-col gap-3 px-3 pt-6">
        {videos.map((video, index) => (
          <CardWithVideo key={video.id || index} video={video} />
        ))}
        </div>
      </div>
    </div>
  );
};

export default Home;

