import React, { useState, useEffect } from 'react';

const SwipeUpIndicator = ({ hasLooped }) => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        if (hasLooped) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [hasLooped]);

    return (
        <div className={`mt-2.5 flex justify-center transition-opacity duration-300 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40px"
                viewBox="0 -960 960 960"
                width="40px"
                fill="#fff"
                className="animate-swipe-up fill-white/80"
            >
                <path d="M80-80v-46.67h89q-61-76-95-165.66Q40-382 40-480q0-98 34-187.67 34-89.66 95-165.66H80V-880h186.67v186.67H220v-127.34q-62.67 72-98 158.67-35.33 86.67-35.33 182T122-298q35.33 86.67 98 158.67v-127.34h46.67V-80H80Zm570-46.33q-21 8-43.5 7.16-22.5-.83-43.17-11.16L298-253.67l10.67-30.66q6.66-19.34 22-31.5Q346-328 366.67-330l88.66-7L340-652.67q-5.33-13.33.67-25.16 6-11.84 19.33-17.17 13.33-5.33 25.5.67T403-675l145.33 398.33-118.66 10.34L592-190.67q8.33 3.67 17.67 3.84 9.33.16 17.66-2.5L785.67-247q36.33-13 52.66-48.17 16.34-35.16 3.34-71.5L782-529.33q-5.33-13.34.67-25.5 6-12.17 19.33-17.5 13.33-5.34 25.5.66t17.5 19.34l59 162.66q23 63-4.5 122.5t-90.5 82.5l-159 58.34Zm-85.33-274.34-56.67-157q-5.33-13.33.67-25.16 6-11.84 19.33-17.17 13.33-5.33 25.17.67 11.83 6 17.16 19.33l57 156.67-62.66 22.66ZM681-443l-43-119q-5.33-13.33.67-25.5T658-605q13.33-5.33 25.17.67 11.83 6 17.16 19.33L744-465.67 681-443Zm6.67 98.67Z"/>
            </svg>
        </div>
    );
};

export default SwipeUpIndicator;
